import React from 'react';
import CloudVendorSvg from '../assets/illustration_brands.svg';

const CloudVendors = () => (
  <>
    <img src={CloudVendorSvg} alt="cloud vendors integrated to one play book" />
  </>
);

export default CloudVendors;
