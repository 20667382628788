import React from 'react';
import illustation from '../assets/illustration_v4.svg';

const HeroImage = () => (
  <>
    <img src={illustation} alt="One play book making team members happy" />
  </>
);

export default HeroImage;
